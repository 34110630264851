@import 'styles/colors';
@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';
@import 'styles/fonts';

$dur: 0.6s;

.root {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    filter: drop-shadow(1.32px 3.95px 6.95px rgba(0, 0, 0, 20%));
    transform: translate(-50%, -50%);
    transition: transform $easeOutQuad $dur;
    will-change: transform, z-index;

    @include rounded-corners($overflow: hidden);

    &.top {
        z-index: 3;
        cursor: grab;
        transform: translate(-50%, -50%) rotate(7deg);

        @media (hover: hover) {
            &:hover {
                transform: translate(-50%, -50%) rotate(7deg) scale(1.05);
                transition: transform $bounce 0.3s;
            }

            &:active {
                cursor: grabbing;
            }
        }
    }

    &.middle {
        z-index: 2;
        transform: translate(-50%, -50%) rotate(-5deg);
    }

    &.bottom {
        z-index: 1;
        transform: translate(-50%, -50%) rotate(3deg);
    }

    // Top card going to bottom
    &.prev {
        animation-duration: $dur;
        animation-fill-mode: forwards;
        animation-name: swipe-off-right;
        animation-timing-function: $easeOutQuad;
        transition: none;
    }

    &.prev.isForward {
        animation-name: swipe-off-left;
    }

    &.withCaption {
        height: 100%;
    }

    .caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 2rem 2rem 3rem;
        background-color: var(--colors-white);
        text-align: center;
        text-wrap: pretty;
    }
}

@keyframes swipe-off-right {
    // scale up
    0% {
        z-index: 4;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.1) rotate(-10deg);
    }

    // fade out and to the right
    60% {
        z-index: 4;
        opacity: 0;
        transform: translate(100%, -60%) scale(1.15) rotate(25deg);
    }

    // stay hidden, scale down, and move into deck
    75% {
        z-index: 1;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8) rotate(0deg);
    }

    // fade and scale in from bottom of deck
    100% {
        z-index: 1;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1) rotate(3deg);
    }
}

@keyframes swipe-off-left {
    // scale up
    0% {
        z-index: 4;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.1) rotate(-10deg);
    }

    // fade out and to the right
    60% {
        z-index: 4;
        opacity: 0;
        transform: translate(-150%, -60%) scale(1.15) rotate(-45deg);
    }

    // stay hidden, scale down, and move into deck
    75% {
        z-index: 1;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8) rotate(0deg);
    }

    // fade and scale in from bottom of deck
    100% {
        z-index: 1;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1) rotate(3deg);
    }
}

.imageInner {
    position: relative;
    width: 100%;
    height: 100%;
}

.flag {
    position: absolute;
    top: 0;
    right: 2.4rem;
    display: flex;
    width: 8.7rem;
    height: 15.6rem;
    flex-direction: column;
    align-items: center;
    background-image: url('/images/campaigns/ncd-23/banner.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    color: var(--colors-cream);
    text-transform: uppercase;
}

.message {
    margin-bottom: 0.2rem;
    font-family: $GTWalsheimCond;
    font-size: 1.37rem;
    font-weight: 700;
    letter-spacing: 0.08em;
    line-height: 1.45rem;
}

.free {
    margin-bottom: 0;
    font-family: $GTWalsheimUltraBold;
    font-size: 2.1rem;
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 1.85rem;
}

.star {
    width: 1.4rem;
    height: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 0.4rem;
}

.image {
    width: 100%;
    object-fit: cover;

    .withCaption & {
        max-width: none;
        height: 85%;
        object-fit: cover;
        object-position: center;
    }
}
